























































































































































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import {ValidationObserver} from "vee-validate";
import ServerProviderResponse from "@/Api/Server/Response/ServerProviderResponse";
import serverApi from "@/Api/Server/ServerApi";
import ServerCreateRequest from "@/Api/Server/Request/ServerCreateRequest";
import ServerResponse from "@/Api/Server/Response/ServerResponse";

@Component
export default class New extends Vue {
    menuStore: MenuStore = getModule(MenuStore, this.$store);
    $refs!: {
        form: InstanceType<typeof ValidationObserver>;
    };
    valid: boolean = false;
    selected: boolean = false;
    loading: boolean = false;
    step: number = 1;
    provider: ServerProviderResponse|null = null;
    server: ServerCreateRequest = {
        features: {
            docker: false,
            mysql: 'mariadb-10.4',
            php: '8.0',
            redis: false,
            supervisor: true,
            webserver: true
        },
        name: "",
        server_os: "ubuntu-20.04"
    }
    phpVersions: any[] = [
        {
            name: this.$t('server.doNotInstallPHP'),
            value: null
        },
        {
            name: 'PHP 8.1',
            value: '8.1'
        },
        {
            name: 'PHP 8.0',
            value: '8.0'
        },
        {
            name: 'PHP 7.4',
            value: '7.4'
        },
        {
            name: 'PHP 7.3',
            value: '7.3'
        },
        {
            name: 'PHP 7.2',
            value: '7.2'
        },
        {
            name: 'PHP 7.1',
            value: '7.1'
        },
        {
            name: 'PHP 7.0',
            value: '7.0'
        },
        {
            name: 'PHP 5.6',
            value: '5.6'
        },
        {
            name: 'PHP 5.5',
            value: '5.5'
        },
        {
            name: 'PHP 5.4',
            value: '5.4'
        },
        {
            name: 'PHP 5.3',
            value: '5.3'
        }
    ];
    databaseVersions: any[] = [
        {
            name: this.$t('server.doNotInstallDatabase'),
            value: null
        },
        {
            name: 'MariaDB 10.5',
            value: 'mariadb-10.5'
        },
        {
            name: 'MariaDB 10.4',
            value: 'mariadb-10.4'
        },
        {
            name: 'MariaDB 10.3',
            value: 'mariadb-10.3'
        },
        {
            name: 'MariaDB 10.2',
            value: 'mariadb-10.2'
        },
        {
            name: 'MariaDB 10.1',
            value: 'mariadb-10.1'
        },
        {
            name: 'MySQL 8.0',
            value: 'mysql-8.0'
        },
    ]

    mounted() {
        this.menuStore.addButtons([{
            name: this.$t('server.serverOverview') as string,
            path: '/servers'
        }])

        serverApi.providers().then((response) => {
            this.provider = (response.data as ServerProviderResponse);
            this.provider = {
                ...this.provider,
                custom: {
                    ...this.provider.custom,
                    public_key: 'mkdir -p /root/.ssh && touch /root/.ssh/authorized_keys && echo "' + this.provider?.custom.public_key + '" >> /root/.ssh/authorized_keys'
                }
            };
        })
    }

    selectCustomProvider(type: string) {
        if (type === 'custom') {
            delete this.server.provider_id;
            this.server = {
                ...this.server,
                custom: {
                    ip_address: "",
                    port: 22
                }
            }
        } else {
            delete this.server.custom;
            this.server = {
                ...this.server,
                provider_id: type
            }
        }
    }

    createServer() {
        this.loading = true;
        this.$refs.form.validate().then((valid) => {
            if (!valid) {
                this.loading = false;
                return;
            }

            serverApi.create(this.server)
                .then((response) => {
                    let data = response.data as ServerResponse;
                    this.$router.push('/servers/' + data.id);
                })
                .catch((error: any) => {
                    this.loading = false;
                    this.$refs.form.setErrors(error.response.data.errors);
                });
        });
    }

    beforeDestroy() {
        this.menuStore.clear();
    }
}
